import React, {useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Logo from "../../assets/Logo.png";
import Gradient from "../../assets/Gradient.png";
import TwitterLogo from "../../assets/TwitterLogo.png";
import InstaLogo from "../../assets/InstagramLogo.png";
import YTLogo from "../../assets/YoutubeLogo.png";
import Linkedin from "../../assets/linkedin.png";
import KissanAI from "../../assets/kissanai.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import {LinearGradient} from "react-text-gradients";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';
import axios from "axios";


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value); // Update the email state variable with the new input
  };

  const addSubscriberEntry = async (event) => {
    event.preventDefault();
    setLoading(true)
    if (email !== "") {
      try {
        const response = await axios.post(
            'https://api.airtable.com/v0/appRqjp1n7IxaYhnW/WaitingList',
            {
              fields: {
                Email: email,
              },
            },
            {
              headers: {
                Authorization: `Bearer patpko0MYGhk4qUFK.e38c000b147d18d4cadbf6becaf1fe40881e56a9e2a5dba41ff8fb4a34702fa2`,
                'Content-Type': 'application/json',
              },
            }
        );

        if (response.status === 200) {
          setLoading(false)
          setEmail(''); // Clear the input field
          toast.success('Subscribed successfully!');
        }
      } catch (error) {
        setLoading(false)
        toast.error('Facing some issue! Could you please try again later!');
        console.error('There was an error sending the data', error);

        // handle error
      }
    }
  }

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const shortText = `Introducing Dhenu 1.0 – the pioneering agricultural model designed specifically for the diverse landscape of Indian agriculture and uniquely bilingual, bridging knowledge in both English and Hindi. Trained on an extensive dataset of high-quality, domain-specific conversations, Dhenu 1.0 is not just a technological breakthrough; It is a gateway to personalized crop advisory tailored to the unique needs of Indian farmers. Our innovative bilingual approach ensures accessibility and inclusivity, breaking language barriers to deliver expert advice in the most familiar way.`;

  const fullText = `${shortText}
  <p>We are committed to excellence and thoroughness. That's why Dhenu 1.0 is currently undergoing rigorous evaluation to guarantee its effectiveness and reliability. Our goal is not just to introduce a tool but to bring a change, a positive transformation in the agricultural practices across India.</p>
  <p>At the heart of Dhenu 1.0 is our commitment to continuous improvement. We understand that today’s agricultural challenges demand evolving solutions. Therefore, while Dhenu 1.0 marks our first step, it is the beginning of a journey towards even more potent and sophisticated versions in the future.</p>
  <p>Watch our demo video to see Dhenu 1.0 in action, and if you're interested in being among the first to experience this revolutionary technology, sign up for our email waitlist. Be a part of this journey to empower farmers with knowledge, precision, and the power of AI-driven agricultural insights.</p>`;


  const handleTermsClick = () => {
    // You can perform any action here, such as navigation or opening a modal
    window.location.href = 'https://kissan.ai/terms';
  };

  const handlePrivacyClick = () => {
    // You can perform any action here, such as navigation or opening a modal
    window.location.href = 'https://kissan.ai/policy';
  };

  return (
      <Box
          sx={{
            backgroundColor: "#0F172A",
            color: "#ffffff",
            fontFamily: "montserrat",
          }}
      >
        <AppBar
            position="static"
            sx={{
              backgroundColor: "#0F172A",
              padding: "16px 0px",
              boxShadow: "none",
              borderBottom: "1px solid #ffffff24",
            }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <img src={Logo} height={40}/>
              <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                    mr: 2,
                    fontFamily: "montserrat",
                    fontWeight: 700,
                    letterSpacing: ".1rem",
                    color: "#ffffff",
                    textDecoration: "none",
                    "&:hover": {
                      color: "#ffffff99",
                    },
                  }}
              >
                KissanAI
              </Typography>

              <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
                  {/* <MenuIcon /> */}
                </IconButton>
              </Box>
              <Box
                  sx={{flexGrow: 1, display: {xs: "none", md: "flex"}}}
              ></Box>

              {/*<Box sx={{flexGrow: 0}}>*/}
              {/*  <Button*/}
              {/*      variant="outlined"*/}
              {/*      sx={{*/}
              {/*        borderColor: "#ffffff",*/}
              {/*        color: "#ffffff",*/}
              {/*        padding: "8px 32px",*/}
              {/*        borderRadius: "100px",*/}
              {/*        "&:hover": {*/}
              {/*          borderColor: "#ffffff",*/}
              {/*          color: "#ffffff80",*/}
              {/*        },*/}
              {/*      }}*/}
              {/*  >*/}
              {/*    Let's Talk*/}
              {/*  </Button>*/}
              {/*</Box>*/}
            </Toolbar>
          </Container>
        </AppBar>

        <Box>
          <Container maxWidth="lg">
            <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
            >
              <Box
                  component="img"
                  src={Gradient}
                  sx={{
                    height: {
                      xs: "60%",
                      md: "80%",
                      lg: "auto",
                    },
                    width: {
                      xs: "60%",
                      md: "80%",
                      lg: "auto",
                    },
                  }}
              />
            </Box>
            <Box
                sx={{
                  padding: "120px 0px",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
            >
              <Box>
                <Typography
                    variant="h1"
                    sx={{
                      fontWeight: "700",
                      fontFamily: "montserrat",
                      // lineHeight:"120px",
                      letterSpacing: "1px",
                      fontSize: {
                        xs: "36px",
                        sm: "48px",
                        md: "56px",
                        lg: "72px",
                      },
                    }}
                >
                  Dhenu Agri LLMs
                  {/*<Typography*/}
                  {/*    component="span"*/}
                  {/*    variant="h1"*/}
                  {/*    sx={{*/}
                  {/*      fontWeight: "900",*/}
                  {/*      ml: "20px",*/}
                  {/*      fontFamily: "montserrat",*/}
                  {/*      // lineHeight:"120px",*/}
                  {/*      letterSpacing: "1px",*/}
                  {/*      fontSize: {*/}
                  {/*        xs: "48px",*/}
                  {/*        sm: "56px",*/}
                  {/*        md: "72px",*/}
                  {/*        lg: "96px",*/}
                  {/*      },*/}
                  {/*    }}*/}
                  {/*>*/}
                  {/*  <LinearGradient gradient={["to left", "#A755F5 ,#F8CDA9"]}>*/}
                  {/*    startups*/}
                  {/*  </LinearGradient>*/}
                  {/*</Typography>*/}
                </Typography>
              </Box>

              <Typography
                  sx={{
                    marginTop: "32px",
                    width: "70%",
                    marginX: "auto",
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "20px",
                      lg: "20px",
                    },
                    // lineHeight:"32px",
                    fontWeight: "600",
                    fontFamily: "montserrat",
                  }}
              >
                Leading the Future of Farming: World's First Agriculture-Specific AI Model
              </Typography>
              {/*<Typography*/}
              {/*    sx={{*/}
              {/*      marginTop: "18px",*/}
              {/*      width: "70%",*/}
              {/*      marginX: "auto",*/}
              {/*      fontSize: {*/}
              {/*        xs: "16px",*/}
              {/*        sm: "18px",*/}
              {/*        md: "20px",*/}
              {/*        lg: "20px",*/}
              {/*      },*/}
              {/*      // lineHeight:"32px",*/}
              {/*      fontWeight: "400",*/}
              {/*      fontFamily: "montserrat",*/}
              {/*    }}*/}
              {/*>*/}
              {/*  Introducing Dhenu 1.0 – the pioneering agricultural model designed specifically for the diverse landscape of Indian agriculture. Trained on an extensive database of high-quality, domain-specific conversations, Dhenu 1.0 is not just a technological breakthrough; it's a gateway to personalized crop advisory tailored to the unique needs of Indian farmers. Our innovative bilingual approach ensures accessibility and inclusivity, breaking language barriers to deliver expert advice in the most familiar way.*/}

              {/*  We are committed to excellence and thoroughness. That's why Dhenu 1.0 is currently undergoing rigorous evaluation to guarantee its effectiveness and reliability. Our goal is not just to introduce a tool but to bring a change, a positive transformation in the agricultural practices across India.*/}

              {/*  Watch our demo video to see Dhenu 1.0 in action, and if you're interested in being among the first to experience this revolutionary technology, sign up for our email waitlist. Be a part of this journey to empower farmers with knowledge, precision, and the power of AI-driven agricultural insights.*/}
              {/*</Typography>*/}
            </Box>

            <Box
                sx={{
                  height: {
                    xs: "240px",
                    sm: "3200px",
                    md: "480px",
                    lg: "640px",
                  },
                }}
            >
              <Box
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                    height: 0,
                    paddingBottom: "50%",
                  }}
              >
                <iframe
                    style={{
                      border: "none",
                      width: "100%",

                      height: "100%",
                      position: "absolute",
                      // width:"100%",
                      // height:{
                      //     xs: '240px', sm: '3200px', md: '480px', lg:'640px'
                      // }
                    }}
                    src="https://www.youtube.com/embed/Z-hXubdVTQ0?si=4riTBi_HLM2QPMAO"
                ></iframe>
              </Box>
            </Box>

            <Box
                sx={{
                  position: "relative",
                }}
            >
              <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 0,
                  }}
              >
                <Box
                    component="img"
                    src={Gradient}
                    sx={{
                      height: {
                        xs: "60%",
                        md: "80%",
                        lg: "auto",
                      },
                      width: {
                        xs: "60%",
                        md: "80%",
                        lg: "auto",
                      },
                    }}
                />
              </Box>
              <Box
                  sx={{
                    paddingTop: "60px",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
              >
                <Box>

                  <Typography
                      sx={{
                        width: "70%",
                        marginX: "auto",
                        fontSize: {
                          xs: "16px",
                          sm: "18px",
                          md: "20px",
                          lg: "20px",
                        },
                        // lineHeight:"32px",
                        fontWeight: "300",
                        fontFamily: "montserrat",
                        textAlign: "left"
                      }}
                      dangerouslySetInnerHTML={{ __html: isExpanded ? fullText : shortText }}
                  />
                  <Button onClick={toggleExpanded}>
                    {isExpanded ? 'Read Less' : 'Read More'}
                  </Button>
                </Box>
                <Box
                    component="img"
                    src={require("../../assets/dhenu_vision.webp")}
                    sx={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      height: {
                        xs: "30%",
                        md: "40%",
                        lg: "50%",
                      },
                      width: {
                        xs: "30%",
                        md: "40%",
                        lg: "50%",
                      },
                    }}
                />
                <Typography
                    sx={{
                      width: "70%",
                      marginX: "auto",
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "20px",
                        lg: "20px",
                      },
                      fontWeight: "300",
                      fontFamily: "montserrat",
                      textAlign: "left"
                    }}
                >
                  Dhenu-vision-lora-v0.1 is an open-source agricultural disease detection model fine-tuned on the Qwen-VL-chat model. It is specifically designed to assist with diseases among three major crops, rice, maize, and wheat, in conversational way. We incorporate Low Rank adaptation techniques for low-cost fine-tuning on agricultural datasets.
                </Typography>
                <Button onClick={() => window.location.href='https://huggingface.co/KissanAI/Dhenu-vision-lora-0.1'}>
                  Checkout Model on Huggingface 
                </Button>
                </Box>

                <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: "auto",
                      marginTop: "32px",
                    }}
                >
                  <form onSubmit={addSubscriberEntry}>
                    <FormControl
                        sx={{
                          m: 1,
                          width: "35ch",
                          backgroundColor: "#ffffff",
                          borderRadius: "32px",
                          padding: "0px 0px",
                          border: "none",
                        }}

                        variant="outlined"
                        classes={{
                          "&.Mui-focused": {
                            border: "none",
                          },
                        }}
                    >
                      {/* <InputLabel htmlFor="outlined-adornment-password">example@xyz.com</InputLabel> */}
                      <OutlinedInput
                          placeholder="example@xyz.com"
                          id="outlined-adornment-password"
                          value={email}
                          onChange={handleEmailChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                  type="submit"
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  sx={{
                                    backgroundColor: "#A755F5",
                                    padding: "16px 32px",
                                    borderRadius: "32px",
                                    marginRight: "-10px",
                                    color: "#ffffff",
                                    fontSize: "16px",
                                    "&:hover": {
                                      backgroundColor: "#A755F5",
                                      color: "#ffffff80",
                                    },
                                  }}
                              >
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    "Subscribe"
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                      />
                    </FormControl>
                  </form>
                </Box>

                <Box>
                  <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                  >
                    <Box
                        sx={{
                          display: "flex",
                          width: "300px",
                          justifyContent: "space-between",
                          marginTop: "30px",
                          alignItems: "center"
                        }}
                    >
                      <a href="https://www.linkedin.com/company/kissanai/" target="_blank" rel="noopener noreferrer">
                        <img style={{width: '50px', height: '50px'}}
                             alt="LinkedIn" src={Linkedin}/></a>
                      <a href="https://kissan.ai" target="_blank" rel="noopener noreferrer">
                        <img style={{width: '150px', height: '50px'}}
                             alt="KissanAI" src={KissanAI}/>
                      </a>
                        {/*<img src={YTLogo}/>*/}

                    </Box>
                  </Box>
                  <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "32px",
                      }}
                  >
                    <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "32px",
                          marginRight: 5,
                          color: "#ffffff96",
                          "&:hover": {
                            color: "#fffffff"
                          }
                        }}
                        onClick={handlePrivacyClick}
                    >
                      Privacy Policy
                    </Typography>
                    <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "32px",
                          color: "#ffffff80",
                          "&:hover": {
                            color: "#fffffff"
                          }
                        }}
                        onClick={handleTermsClick}
                    >
                      Terms & Service
                    </Typography>
                    {/*<Typography*/}
                    {/*    sx={{*/}
                    {/*      fontSize: "16px", fontWeight: "400",*/}
                    {/*      color: "#ffffff96",*/}
                    {/*      "&:hover": {*/}
                    {/*        color: "#fffffff"*/}
                    {/*      }, lineHeight: "32px"*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*  Cookies*/}
                    {/*</Typography>*/}
                  </Box>
                  <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#ffffff48",
                        lineHeight: "32px",
                        fontWeight: "400",
                        textAlign: "center",
                        mt: "48px",
                        pb: "24px",
                      }}
                  >
                    @2023 KissanAI - All rights reserved
                  </Typography>
                </Box>
              </Box>
            
          </Container>
        </Box>
        <ToastContainer position="top-center"/>
      </Box>
  );
}

export default ResponsiveAppBar;
